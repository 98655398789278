import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { PageTitle, Seo, VideoPlayer } from "../../components/common"
import { FaGithub, FaShareSquare } from "react-icons/fa"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { motion } from "framer-motion"

const SingleProjectPage = ({ data: { contentfulProjects: project } }) => {
  const projectDescription = JSON.parse(project?.description?.raw)

  const variants = {
    initial: { y: 100, opacity: 0 },
    whileInView: { y: 1, opacity: 1 },
    transition: { duration: 0.5 },
  }

  return (
    <>
      <Seo
        title={project?.title}
        description={projectDescription}
        image={project?.thumbnail?.publicUrl}
      />

      <Layout>
        <PageTitle title={project?.title} />
        <Container className="section shaped-bcg-svg">
          <SectionCenter className="section-center">
            <VideoPlayer videoSrcURL={project?.videoUrl} />
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
              }}
              className="links"
            >
              <a
                href={project?.liveUrl}
                target="_blank"
                rel="noreferrer"
                title="Live Demo"
              >
                <FaShareSquare /> Live Demo
              </a>
              <a
                href={project?.repoUrl}
                target="_blank"
                rel="noreferrer"
                title="Source Code"
              >
                <FaGithub /> Source Code
              </a>
            </motion.div>

            <motion.article
              variants={variants}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
              transition="transition"
              className="description"
            >
              {documentToReactComponents(projectDescription)}
            </motion.article>
          </SectionCenter>
        </Container>
      </Layout>
    </>
  )
}

export const query = graphql`
  query MyQuery($slug: String) {
    contentfulProjects(slug: { eq: $slug }) {
      title
      slug
      videoUrl
      liveUrl
      repoUrl
      thumbnail {
        publicUrl
      }
      description {
        raw
      }
    }
  }
`

const Container = styled.section`
  background-color: var(--clr-black2);
`

const SectionCenter = styled.div`
  display: grid;
  place-items: center;

  .links {
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    a {
      color: var(--clr-white);

      svg {
        font-size: 1.5rem;
        transition: var(--transition);
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
        &:hover {
          transform: scale(1.25);
        }
      }

      &:last-of-type {
        border-left: 2px solid var(--clr-grey);
        padding-left: 1.5rem;
      }
    }
  }

  .description {
    font-size: 1rem;
    margin: 2rem auto 0;

    h1 {
      font-size: 3.052rem;
    }
    h2 {
      font-size: 2.441rem;
    }
    h3 {
      font-size: 1.953rem;
    }
    h4 {
      font-size: 1.563rem;
    }
    h5 {
      font-size: 1.25rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
      margin-bottom: 1.38rem;
      line-height: 1;
      text-transform: capitalize;
      letter-spacing: 1px;
    }

    img {
      width: 100%;
      margin: 0.5rem auto;
      padding: 0 2rem;
      max-width: 592px;
    }

    blockquote {
      border-left: 3px solid var(--clr-grey);
      font-style: italic;
      padding: 0.5rem 1rem;

      p {
        margin-bottom: 0;
      }
    }

    code {
      background: #111;
      color: rgb(50, 77, 103);
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      margin-left: 1rem;
      font-family: "Courier New", Courier, monospace;
    }

    pre code {
      display: block;
      white-space: pre;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      padding: 1rem 1.5rem 0;
      border-radius: 0.5rem;
    }

    @media (max-width: 592px) {
      font-size: 0.875rem;

      h1,
      h2,
      h3,
      h4,
      h5 {
        line-height: 1.3;
      }

      h1 {
        font-size: 3.052rem;
      }
      h2 {
        font-size: 2.441rem;
      }
      h3 {
        font-size: 1.953rem;
      }
      h4 {
        font-size: 1.25rem;
      }
      h5 {
        font-size: 0.875rem;
      }
    }
  }
`

export default SingleProjectPage
